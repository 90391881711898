import { useEffect, useState } from 'react'
import { rtdb, auth } from './firebase'
import { onDisconnect } from 'firebase/database'

import {
  signInAnonymously,
  setPersistence,
  browserLocalPersistence
} from 'firebase/auth'

import {
  query,
  ref,
  set,
//  get,
  onValue,
  off,
} from 'firebase/database'

import useZoom from './useZoom'

// async function testFirebase(uid: String) {
//   console.log('test firebase')
//   const test = {
//     testAuth: '',
//     testToken: '',
//     testWrite: '',
//     testRead: '',
//     testSubscribe: '',
//     testFetch: ''
//   }

//   const uidRef = ref(rtdb, `mobile/pool/${uid}/test`)

//   try {
//     console.log('test auth')
//     const user = await signInAnonymously(auth)
//     await set(ref(rtdb, `mobile/pool/${uid}/log/testAuth`), !!user ? 'success' : 'failed')
//   } catch (err) {
//     set(ref(rtdb, `mobile/pool/${uid}/error/testAuth`), err?.toString())
//   }

//   try {
//     console.log('test subscribe')
//     onValue(query(uidRef), async (snapshot) => {
//       const data = snapshot.val()
//       if (data === null) {
//         test.testSubscribe = 'received initial'
//         await set(ref(rtdb, `mobile/pool/${uid}/log/testSubscribe`), test.testSubscribe)
//         await set(uidRef, 'online')
//       } else if (data === 'online') {
//         test.testSubscribe = 'received after update'
//         await set(ref(rtdb, `mobile/pool/${uid}/log/testSubscribe`), test.testSubscribe)
//       }
//     });
//   } catch (err) {
//     set(ref(rtdb, `mobile/pool/${uid}/error/testSubscribe`), err?.toString())
//   }

//   try {
//     console.log('test write')
//     await set(ref(rtdb, `mobile/pool/${uid}/log/testWrite`), 'success')
//   } catch (err) {
//     set(ref(rtdb, `mobile/pool/${uid}/error/testWrite`), err?.toString())
//   }

//   try {
//     console.log('test read')
//     const snapshot = await get(query(ref(rtdb, `mobile/pool/${uid}/log`)))
//     const data = snapshot.val()
//     await set(ref(rtdb, `mobile/pool/${uid}/log/testRead`), !!data ? 'success' : 'failed')
//   } catch (err) {
//     set(ref(rtdb, `mobile/pool/${uid}/error/testRead`), err?.toString())
//   }

//   let token;
//   try {
//     console.log('test token')
//     if (auth.currentUser) {
//       token = await auth.currentUser.getIdToken(true)
//       test.testToken = token
//     } else {
//       test.testToken = 'currentUser is null'
//     }
//     await set(ref(rtdb, `mobile/pool/${uid}/log/testToken`), test.testToken)
//   } catch (err) {
//     set(ref(rtdb, `mobile/pool/${uid}/error/testToken`), err?.toString())
//   }

//   if (token) {
//     try {
//       console.log('test fetch')
//       const res = await fetch(`${dbPath}/mobile/pool/${uid}/test.json?auth=${token}`)
//       const data = await res.json()
//       await set(ref(rtdb, `mobile/pool/${uid}/log/testFetch`), !!data ? 'success' : 'failed')
//     } catch (err) {
//       set(ref(rtdb, `mobile/pool/${uid}/error/testFetch`), err?.toString())
//     }
//   }
// }

export default function useFirebase() {

  const [uid, setUid] = useState<String>()
  const [data, setData] = useState<any>()
  const [error, setError] = useState<String>('')
  const [zoom] = useZoom()

  useEffect(() => {
    if (zoom.zoomCtx && zoom.zoomCtx.deviceId) {
      console.log('setUid', zoom.zoomCtx.deviceId)
      const id = zoom.zoomCtx.deviceId.split('/').join('')
      setUid(`zoom/${id}`)
    }
  }, [zoom])

  useEffect(() => {
    if (!uid && zoom.issue) {
      console.log('zoom issue', zoom.issue)
      console.log('authenticate')
      const authenticateAnonymously = async () => {
        try {
          await setPersistence(auth, browserLocalPersistence)
          const user = await signInAnonymously(auth)
          if (user && user.user && user.user.uid) {
            console.log('setUid', user.user.uid)
            setUid(user.user.uid)
          }
        } catch (error: any) {
          setUid('has9d9d9asasdj0293usakjdk');
          setData(null);
          // setError(error ? error.message : error)
        }
      };
  
      if (!auth.currentUser) {
        authenticateAnonymously()
      } else {
        console.log('setUid', auth.currentUser.uid)
        setUid(auth.currentUser.uid)
      }
    } else if (uid) {

      const subscribe = async () => {
        console.log('subscribe');
        try {
          const uidRef = ref(rtdb, `mobile/pool/${uid}`)
          onValue(query(uidRef), (snapshot) => {
            console.log('receive data')
            const data = snapshot.val()
            console.log('data', data);
            if (data === null) set(uidRef, 'online')
            else if (data !== 'online') {
              const onlineRef = ref(rtdb, `mobile/connect/${uid}`)
              set(onlineRef, {status:'online', ua: navigator.userAgent})
              onDisconnect(onlineRef).update({status: 'offline', ua: navigator.userAgent})
              setData(data)
            }
            else setData(null)
          });
        } catch (error: any) {
          // setError(error ? error.message : error)
          console.log('error: receive data')
          setData(null)
        }
      }

      const connectZoom = async () => {
        try {
          await setPersistence(auth, browserLocalPersistence)
          await signInAnonymously(auth)
          console.log('authenticated')
          await subscribe()
        } catch (error: any) {
         // setError(error ? error.message : error)
         console.log('receive data')
         setData(null)
        }
      };

      if (zoom && zoom.zoomCtx) {
        connectZoom()
      } else {
        console.log('authenticated')
        subscribe()
      }
    }
  }, [uid, zoom])

  useEffect(() => {
    return () => {
      if (uid) {
        console.log('unsubscribe')
        off(query(ref(rtdb, `mobile/pool/${uid}`)))
      }
    }
  }, [uid])

  return [ uid, data, error ]
}
