import { useEffect, useState } from "react";
import "./App.css";
import qrcode from "qrcode";
import useFirebase from "./useFirebase";

export default function App() {
  const [uid, data, error] = useFirebase();
  const [qr, setQr] = useState<string>("");

  useEffect(() => {
    if (uid && data === null) {
      qrcode.toDataURL(uid, { width: 392 }).then(setQr);
    }
  }, [uid, data]);

  return (
    <>
      {error && <div className="socket-error">Offline: {error}</div>}

      {uid && !data && qr && (
        <div className="s-content__border">
          <div className="s-content__logo">
            <img src="images/logo.svg" alt="#" />
          </div>
          <div className="s-content__container">
            <div className="s-content__qr">
              <img src={qr} alt="qr-code" />
            </div>
            <div className="s-content__line"></div>
            <div className="s-content__message">
              <div className="s-content__title">
                Connect your screen with the Seenspire mobile app
              </div>
              <div className="s-content__instruction">
                <div className="s-content__point">
                  <div className="s-content__number">1</div>
                  <div className="s-content__text">Download the Seenspire app from either the Apple App Store or Google Play Store.</div>
                </div>
                <div className="s-content__point">
                  <div className="s-content__number">2</div>
                  <div className="s-content__text">When instructed in the app, scan the QR code.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {uid && data && !data.feedUrl && (
        <div className="s-content__border">
          <div className="s-content__logo">
            <img src="images/logo.svg"  alt="#" />
          </div>
          <div className="s-content__container">
            <div className="s-content__stream">
              <div className="s-content__stream-image">
                <img src="images/stream-phone.png"  alt="#" />
              </div>
              <div className="s-content__stream-message">
                <div className="s-content__connected">
                  <div className="s-content__connected-img">
                    <img src="images/success.svg" />
                  </div>
                  <div className="s-content__connected-text">Connected</div>
                </div>
                <div className="s-content__stream-instruction">
                  <div className="s-content__stream-title">
                    Play stream
                  </div>
                  <div className="s-content__stream-text">
                    To play a stream, simply select a stream within the Seenspire mobile app.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {uid && data && data.feedUrl && (
        <iframe
          className="s-presentation__content"
          title="presentation"
          frameBorder="none"
          src={data.feedUrl}
        ></iframe>
      )}
    </>
  );
}
