import { useEffect, useState } from 'react'
import zoomSdk, { GetZoomRoomContextResponse } from '@zoom/appssdk'

async function getZoomContext() {
  try {
    await zoomSdk.config({ capabilities: ["getZoomRoomContext"] })
  } catch (err) {
    console.log('zoom error', err)
  }

  let context
  try {
    context = await zoomSdk.getZoomRoomContext()
    return context
  } catch (err: any) {
    console.log('zoom error', err)
    throw new Error(err)
  }
}

export default function useZoom() {
  const [result, setResult] = useState<{zoomCtx: GetZoomRoomContextResponse | null, issue: boolean}>({zoomCtx: null, issue: false})

  useEffect(() => {
    // setResult({ zoomCtx: { deviceId: 'my/zoom/device', roomId: 'my/zoom/room' }, issue: false})
    getZoomContext().then(ctx => {
      setResult({ zoomCtx: ctx, issue: false })
    }).catch(() => {
      setResult({ zoomCtx: null, issue: true })
    })
  }, [])

  return [result]
}
