import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/auth";

export interface IFirebaseConfig {
  apiKey?: string;
  authDomain?: string;
  databaseURL?: string;
  projectId?: string;
  storageBucket?: string;
  messagingSenderId?: string;
  appId?: string;
}

const firebaseConfig: IFirebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

const app = !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app();

if (process.env.REACT_APP_ENV === 'development') {
  firebase.database().useEmulator('localhost', 9000)
  firebase.auth().useEmulator('http://localhost:9099/')
}

export default app;
export const auth = app.auth();
export const rtdb = app.database();
export const dbPath = firebaseConfig.databaseURL;
